<template>
    <v-main fluid class="mt-n6">
        <v-container class="p-0 px-2">
            <v-row class="d-flex justify-content-center">
                <v-col cols="12" sm="10" md="8" lg="5">
                    <div class="bg-white border p-5 mt-5">
                        <h1 class="display-3">{{$t("TH_ResetPassword")}}</h1>
                        <p class="caption">{{$t("IM_ResetEmailAdressUsedDuringRegistration")}}</p>
                        <hr />
                        <v-text-field id="email"
                                      ref="email"
                                      autocomplete="off"
                                      v-model="model.email"
                                      v-bind:error="errors.email"
                                      :label="$t('FL_Email')"
                                      dense
                                      outlined
                                      hide-details
                                      color="accent"
                                      append-icon="email"
                                      v-bind:placeholder="$t('FL_Email')"
                                      class="mb-3"></v-text-field>

                        <v-row class="my-3 d-flex justify-content-center align-items-center">
                            <v-col cols="auto" class="mr-0 pr-0">
                                <v-img id="captchaimg" height="106" width="300" :src="captchaUrl">
                                    <template v-slot:placeholder>
                                        <v-skeleton-loader class="my-auto"
                                                           type="image" height="106" width="300"></v-skeleton-loader>
                                    </template>
                                </v-img>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn depressed icon v-on:click="ReloadCaptcha()" color="secondary">
                                    <v-icon>cached</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-text-field v-model="model.captcha" v-bind:error="errors.captcha" dense outlined hide-details color="accent" append-icon="mdi-shield-check" 
                                      v-bind:placeholder="$t('FL_SecurityCode')" 
                                      :label="$t('FL_SecurityCode')"
                                      class="mb-5"></v-text-field>

                        <v-btn depressed block color="primary white--text" v-on:click="ResetPassword()">{{$t("AL_ResetPassword")}}</v-btn>

                        <hr />

                        <p><router-link to="KayitTalebi">{{$t("AL_ApplicationAkbankSupplier")}}</router-link></p>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="processing" persistent>
            <v-card color="transparent" dark class="py-5">
                <v-card-text class="text-center py-5">
                    <v-progress-circular indeterminate
                                         :size="50"
                                         color="white"></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<style>
    .v-dialog {
        box-shadow: none !important;
    }
</style>

<script>
    import { FormMixIn } from "@/MixIns/FormMixins";
    export default {
        name: "Forgot",
        mixins: [FormMixIn],
        data: function () {
            return {
                processing: false,
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                imgURL: '',

                model: {
                    email: '',
                    captcha: ''
                },

                errors: {
                    email: false,
                    captcha: false
                },
                ErrorText: [],
                customErrorText: [],

                pageInputs: {
                    'Forgot': ['email', 'captcha'],
                },
                captchaUrl: '/pages/BlankCaptcha.bmp',
                keys: {
                    'email': 'FL_Email',
                    'captcha': 'FL_SecurityCode'
                },
            };
        },
        methods: {
            ResetPassword() {
                var self = this;
                if (this.ValidateBeforeAction()) {
                    this.processing = true;
                    var payload = {
                        action: 'Forgot',
                        formdata: {
                            formInputs: { email: this.model.email },
                        },
                        captcha: this.model.captcha,
                    };
                    this.$store.dispatch("AccountAction", payload)
                        .then(response => {
                            this.processing = false;
                            if (response.data.errorCode == 0) {
                                self.ShowDialog(response.data.errorMessage, 'B', 'dialog');
                                this.ReloadCaptcha();
                                this.ClearModel();
                                this.$router.push({ name: 'login' });
                            }
                            else {
                                self.ShowDialog(response.data.errorMessage, 'E', 'dialog');
                                this.ReloadCaptcha();
                                this.model.captcha = '';
                            }
                        })
                }
                else {
                    self.ShowDialog(this.customErrorText, 'E', 'notification');
                }
            },
            ValidateBeforeAction: function () {
                this.ClearErrors();
                for (var ctrl in this.errors) {
                    var data = this.model[ctrl];

                    if (ctrl == 'email') {
                        this.errors[ctrl] = (data == '');

                        if (!this.errors[ctrl]) {
                            var isEmailValidated = this.ValidateEmail(this.model.email);

                            if (!isEmailValidated)
                                this.customErrorText.push(this.$root.$i18n.t('VE_WarnEmailMustBeInRightFormat'));

                            this.errors[ctrl] = (!isEmailValidated);
                        }

                    }
                    else {
                        this.errors[ctrl] = (data == '');
                    }
                }

                this.PrepareErrorMessage(this.pageInputs['Forgot']);

                return this.customErrorText.length == 0;
            },
            ValidateEmail: function (value) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(value).toLowerCase());
            },
            ClearErrors: function () {
                this.customErrorText = [];

                for (var reset in this.errors)
                    this.errors[reset] = false;
            },
            PrepareErrorMessage: function (pageInputs) {
                for (var err in this.errors) {
                    if (pageInputs.indexOf(err) > -1) {
                        if (this.errors[err] == true) {
                            if (this.model[err] == '')
                                this.customErrorText.push(this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t(this.keys[err]) }));
                        }
                    }
                }
            },
            ClearModel() {
                this.model.email = '';
                this.model.captcha = '';
            },
            ReloadCaptcha()
            {
                var date = Math.floor(new Date().getTime() / 1000).toString();
                this.captchaUrl = "/api/Captcha?rnd1=" + date;
                //this.$nextTick(() => this.captchaUrl = "/api/Captcha?rnd=" + Math.floor(new Date().getTime()).toString());
            }
        },
        mounted: function ()
        {
            //this.ReloadCaptcha();
            window.setTimeout(this.ReloadCaptcha, 1000);
        },
    }
</script>